<template>
  <div class="pt0">
    <div style="background: #865a18; padding-bottom: 40px">
      <h5 class="pt40 center" style="color: #fff">Collaborators</h5>
      <h1 style="color: white" class="center">Knowledge Society</h1>
      <div
        class="mb20 pl20 mt20 centered box center"
        style="background: #865a18"
      >
        <i class="search icon" style="color: white"></i>
        <input
          class="noborder knowledge_search"
          v-model="search"
          style="color: white"
          placeholder="Search By Name"
        />
      </div>
      <div class="mentors_parent">
        <div class="mx12 mentors mentor_list" :id="ID">
          <div
            v-for="(m, ix) in activeMentors"
            :key="ix"
            class="mentor_card pointer"
            @click="openModal('mentor-' + ix)"
          >
            <div
              :style="{
                background: 'url(' + m.image.url + ') center / cover no-repeat',
              }"
              class="image"
              style="
                width: 60px;
                height: 60px;
                whitespace: nowrap;
                flex-shrink: 0;
              "
            ></div>
            <div class="myauto flex vertical" style="height: fit-content">
              <h6 class="px20 fs14 mobile-fs12">{{ m.name }}</h6>
              <p class="pt0 px20 fs12 aaa">
                {{ m.numberOfCourses }}
                {{ m.numberOfCourses == 1 ? "Course / Lecture" : "Courses" }}
              </p>
            </div>
            <!-- Score -->
            <div class="ui modal py20 px40" :id="'mentor-' + ix">
              <h6 class="pb20" style="border-bottom: 1px solid #eee">
                {{ m.name }}
              </h6>
              <div class="flex">
                <div
                  :style="{
                    background:
                      'url(' + m.image.url + ') center / cover no-repeat',
                  }"
                  class="image mt20 ml0 mrauto br8 noshrink"
                  style="width: 140px; height: 140px"
                ></div>
                <div class="ml20 mt20">
                  <!-- <div v-if="getCourses({ mentor: m.id }).length > 0">
                    <p class="mt20 bold mb8">Courses</p>
                    <a
                      :href="course.link"
                      target="_blank"
                      class="fs14"
                      v-for="course in getCourses({ mentor: m.id })"
                      :key="course.id"
                    >
                      <p>{{ course.title }}</p>
                    </a>
                  </div> -->
                  <p class="mt20 bold mb8">About</p>
                  <p>{{ m.about }}</p>
                </div>
              </div>
            </div>
            <!-- -->
          </div>
        </div>
      </div>
      <div class="mt12 pb40 centered flex">
        <div
          class="w24 h24 br20 mx4 flex"
          :style="{
            background: activePage == i ? 'rgba(255, 255, 255, 0.3)' : '',
          }"
          style="color: white; cursor: pointer"
          v-for="(p, i) in new Array(mentorPages).fill(1)"
          :key="i"
          @click="activePage = i"
        >
          <span class="mauto">{{ i + 1 }}</span>
        </div>
      </div>
    </div>
    <div style="padding-bottom: 40px">
      <h5 class="pt40 center">Collaborators</h5>
      <h1 class="center">Association Partners</h1>
      <div class="partner-tabs flex">
        <h4
          :class="{ active: associationState == 'current' }"
          @click="associationState = 'current'"
          class="partner-tab"
        >
          Current Partners
        </h4>
        <h4
          :class="{ active: associationState == 'past' }"
          @click="associationState = 'past'"
          class="partner-tab"
        >
          Past Partners
        </h4>
      </div>
      <div class="flex wrap pt40">
        <div
          v-for="l in $bus.siteData[associationKey + 'Ra']"
          :key="l.url"
          class="partner flex"
          style="height: 60px; margin-top: 20px"
        >
          <div
            class="ml40 mrauto"
            v-if="l.image && l.image.url"
            style="width: 100px; height: 100%"
            :style="{
              background: 'url(' + l.image.url + ') center / contain no-repeat',
            }"
          ></div>
        </div>
      </div>
    </div>
    <div style="background: #eeeeee" class="pb40">
      <h5 class="pt40 center">Collaborators</h5>
      <h1 class="center">Corporate Partners</h1>
      <div>
        <div class="partner-tabs flex">
          <h4
            :class="{ active: corporateState == 'current' }"
            @click="corporateState = 'current'"
            class="partner-tab"
          >
            Current Partners
          </h4>
          <h4
            :class="{ active: corporateState == 'past' }"
            @click="corporateState = 'past'"
            class="partner-tab x"
          >
            Past Partners
          </h4>
        </div>
        <div class="flex wrap x">
          <div
            v-for="l in $bus.siteData[corporateKey + 'Ra']"
            :key="l.url"
            class="partner flex"
            style="height: 60px; margin-top: 20px"
          >
            <div
              v-if="l.image && l.image.url"
              class="ml40 mrauto"
              style="width: 100px; height: 100%"
              :style="{
                background:
                  'url(' + l.image.url + ') center / contain no-repeat',
              }"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="css">
.partner_header {
  margin-left: -40px;
  text-align: center;
  width: calc(100% + 80px);
  background: #eee;
  color: #585757;
}
.partner-tabs {
  width: 100%;
  min-width: 400px;
  overflow-x: scroll;
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid #eee;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.partner-tabs::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.partner-tabs {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.partner-tab {
  white-space: nowrap;
  font-size: 24px;
  text-align: center;
  padding: 24px 40px;
  width: 30%;
  font-weight: 400;
  color: #777;
  cursor: pointer;
  letter-spacing: 0.01em;
  transition: 0.3s;
  border-bottom: 5px solid transparent;
}
.partner-tab.active {
  border-bottom: 5px solid #b98744;
  color: #252525;
  font-weight: 700;
}
.knowledge_search {
  background: none;
  border: none;
  outline: none;
}
.knowledge_search::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
@media (max-width: 800px) {
  .partner-tab {
    width: 50%;
    font-size: 12px;
    padding: 12px;
  }
}
.partner {
  width: 25%;
}
@media (max-width: 600px) {
  .partner {
    width: 50%;
  }
}

.knowledge_search {
  background: none;
  border: none;
  outline: none;
}
.knowledge_search::placeholder {
  color: rgba(255, 255, 255, 0.6);
}
.archause_home {
  width: 100%;
  position: relative;
  padding: 0px;
  overflow: hidden;
}

@media (max-width: 800px) {
  .archause_home {
    padding: 0px;
  }
}
.banner {
  padding: 8px;
  height: fit-content;
  white-space: nowrap;
  background: #252525;
  display: flex;
  justify-content: center;
}

.banner p {
  height: 24px;
  font-size: 16px;
  margin: auto 4px;
  font-weight: 600;
}
@media (max-width: 800px) {
  .banner p {
    font-size: 14px;
  }
  .banner {
    overflow-x: scroll;
  }
}
.banner::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.banner {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* .smear {
    background: url('../assets/archause_section_smear.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 300px;
    position: relative;
    height: 500px;
    top: -190px;
    z-index: -1;
    left: 190px;
  } */

.section2 {
  height: fit-content;
}
@media (max-width: 800px) {
  .section2 {
    height: fit-content;
  }
}

/* .cover {
    border-bottom-left-radius: 20px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 300px 80px;
    border-color: transparent transparent #B98744 transparent;
  } */
@media (min-width: 800px) {
  .together-text {
    font-size: 20px;
    margin-left: auto;
    margin: auto;
  }
}
.mentors {
  background: #865a18;
  display: flex;
  flex-wrap: wrap;
}

.mentor_card {
  overflow: hidden;
  display: flex;
  background: white;
  border-radius: 4px;
  margin-right: 12px;
  margin-bottom: 12px;
  width: 160px;
}

.mentors_parent {
  background: #865a18;
  padding: 40px 100px;
}
@media (max-width: 800px) {
  .mentors_parent {
    padding: 0px;
    background: #865a18;
  }
}

.mentor_list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.mentor_list > * {
  width: 20%;
}

.mentor_list > * > div {
  height: 400px;
}

@media (max-width: 2000px) {
  .mentor_list > * > div {
    height: 240px;
  }
}

@media (max-width: 1200px) {
  .mentor_list > * > div {
    height: 220px;
  }
}

.mentor_list > * {
  width: 25%;
}
@media (max-width: 1200px) {
  .mentor_list > * {
    width: 50%;
  }
}
@media (max-width: 600px) {
  .mentor_list > * {
    width: 100%;
  }
}

.me_chip {
  background: #f5f5f5;
  border-radius: 40px;
  color: #585757;
  cursor: pointer;
  width: fit-content;
  white-space: nowrap;
  padding: 6px 18px;
  margin: 12px 0px 12px 20px;
  transition: 0.2s;
  font-weight: 600;
  border: 2px solid transparent;
}
.me_chip:hover {
  background: #fdf7f0;
  color: #6b3e1e;
}
.me_chip.active,
.me_chip:active {
  background: #fdf7f0;
  border: 2px solid #6b3e1e;
  color: #6b3e1e;
}
</style>

<script>
export default {
  name: "partners",
  data() {
    return {
      corporateState: "current",
      associationState: "current",
      mentors: [],
      acedge: {},
      activePage: 0,
      search: "",
    };
  },
  mounted() {
    /* eslint-disable-next-line no-undef */
    $(document).scrollTop(0);
    console.log("Loading Partners");
    this.$req
      .get("acedge")
      .then((r) => {
        this.acedge = r.data;
      })
      .catch((e) => console.log(e));
  },
  methods: {
    openModal: function (id) {
      /* eslint-disable-next-line no-undef */
      $("#" + id).modal("show");
    },
  },
  watch: {
    filteredMentors: function () {
      this.activePage = 0;
    },
  },
  computed: {
    corporateKey: function () {
      let state = this.corporateState;
      return state == "current" ? "corporatePartners" : "corporatePastPartners";
    },
    associationKey: function () {
      let state = this.associationState;
      return state == "current"
        ? "associationPartners"
        : "associationPastPartners";
    },
    filteredMentors: function () {
      if (!this.acedge) return [];
      if (this.search == "") return this.acedge.mentors;
      return this.acedge.mentors.filter(
        (m) =>
          m.name.toLowerCase().includes(this.search.toLowerCase()) && m.image
      );
    },
    mentorPages: function () {
      let count = this.$mq == "desktop" ? 18 : this.$mq == "mobile" ? 12 : 15;
      return Math.ceil(this.filteredMentors?.length / count);
    },
    activeMentors: function () {
      let count = this.$mq == "desktop" ? 18 : this.$mq == "mobile" ? 12 : 15;
      return (
        this.acedge &&
        this.filteredMentors?.slice(
          count * this.activePage,
          count * (this.activePage + 1)
        )
      );
    },
  },
};
</script>
